import {
  Grid,
  GridColumn,
  Text,
  ThemeProvider,
  UnbrandedEducationTheme
} from "@modernatx/ui-kit-react";
import { GetServerSidePropsContext, InferGetServerSidePropsType } from "next";
import { getServerSession } from "next-auth/next";
import { getProviders, signIn } from "next-auth/react";
import { useEffect } from "react";
import { Global } from "theme-ui";

import { authOptions } from "../api/auth/[...nextauth]";

const SignIn = ({
  providers,
  callbackUrl
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  useEffect(() => {
    // Automatically trigger signIn for Okta
    const oktaProvider = Object.values(providers).find((provider) => provider.id === "okta");
    if (oktaProvider) {
      signIn(oktaProvider.id, { callbackUrl });
    }
  }, [providers, callbackUrl]);

  return (
    <ThemeProvider theme={UnbrandedEducationTheme}>
      <Global
        styles={{
          html: {
            backgroundColor: "background01"
          }
        }}
      />
      <Grid>
        <GridColumn
          sx={{
            alignItems: "center",
            display: "flex",
            height: "100vh",
            justifyContent: "center"
          }}
        >
          <Text size="lg" as="p">
            Redirecting to Okta...
          </Text>
        </GridColumn>
      </Grid>
    </ThemeProvider>
  );
};

export default SignIn;

export async function getServerSideProps(context: GetServerSidePropsContext) {
  const session = await getServerSession(context.req, context.res, authOptions);

  // If the user is already logged in, redirect them to the home page.
  if (session) {
    return { redirect: { destination: "/", permanent: false } };
  }

  const providers = await getProviders();
  const callbackUrl = Array.isArray(context.query.callbackUrl)
    ? context.query.callbackUrl[0]
    : context.query.callbackUrl || "/";

  return {
    props: { providers: providers ?? [], callbackUrl }
  };
}
